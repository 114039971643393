@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;400&display=swap');

body {
    font-family: 'Roboto', sans-serif;
    font-size: large;
    color: #fff;
    text-align: center;
    background-color: #000;
}

h2 {
    color: #fff;
    background-color: #e8145b;
    padding: 15px;
}

hr {
    margin: 30px auto 30px auto;
}

img {
    margin: auto;
}

#outer-content-container {
    width: 80%;
    height: 100vh;
    margin: auto;
}

#skills-container {
    margin-top: 25px;
}

.nav-link {
    color: #fff;
}

.nav-link:visited {
    color: #fff;
}

.nav-link:hover {
    color: #e8145b;
}

.grecaptcha-badge {
    z-index: 1001;
}

.project-container, .contact {
    color: #393f4a;
    background-color: #fff;
}

.project-description {
    font-size: medium;
    margin: 20px 25px 0px;
}

.project-links, button {
    text-decoration: none;
    color: #fff;
    background-color: #e8145b;
    border: none;
    padding: 10px;
    margin: 20px 0px 20px 0px;
    z-index: 1000;
}

.project-links:hover {
    text-decoration: none;
    color: #fff;
    background-color: #c4114d;
    padding: 10px;
}

.carousel-controls {
    background-color: #c4114d;
}

.action-container {
    display: flex;
    justify-content: center;
}

.contact-form {
    padding: 20px 25px 0px;
}

@media only screen and (max-width: 698px) {
    #outer-content-container {
        width: 95%;
    }
    .project-description, .action-container {
        font-size: small;
        padding: 0px 5px;
    }
    button, .project-links {
        padding: 8px;
    }
    .project-links:hover {
        padding: 8px;
    }
}