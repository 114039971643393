@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100;400&display=swap);
/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

html, body, div, span, applet, object, iframe, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}
html {
  box-sizing: border-box;
}
body {
    font-family: 'Roboto', sans-serif;
    font-size: large;
    color: #fff;
    text-align: center;
    background-color: #000;
}

h2 {
    color: #fff;
    background-color: #e8145b;
    padding: 15px;
}

hr {
    margin: 30px auto 30px auto;
}

img {
    margin: auto;
}

#outer-content-container {
    width: 80%;
    height: 100vh;
    margin: auto;
}

#skills-container {
    margin-top: 25px;
}

.nav-link {
    color: #fff;
}

.nav-link:visited {
    color: #fff;
}

.nav-link:hover {
    color: #e8145b;
}

.grecaptcha-badge {
    z-index: 1001;
}

.project-container, .contact {
    color: #393f4a;
    background-color: #fff;
}

.project-description {
    font-size: medium;
    margin: 20px 25px 0px;
}

.project-links, button {
    text-decoration: none;
    color: #fff;
    background-color: #e8145b;
    border: none;
    padding: 10px;
    margin: 20px 0px 20px 0px;
    z-index: 1000;
}

.project-links:hover {
    text-decoration: none;
    color: #fff;
    background-color: #c4114d;
    padding: 10px;
}

.carousel-controls {
    background-color: #c4114d;
}

.action-container {
    display: flex;
    justify-content: center;
}

.contact-form {
    padding: 20px 25px 0px;
}

@media only screen and (max-width: 698px) {
    #outer-content-container {
        width: 95%;
    }
    .project-description, .action-container {
        font-size: small;
        padding: 0px 5px;
    }
    button, .project-links {
        padding: 8px;
    }
    .project-links:hover {
        padding: 8px;
    }
}
